import React from "react"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import starIcon from "../../assets/images/star-icon.png"

const ServiceInfo = ({ info }) => {
  const title = info?.title ?? "Service Information"
  const longDesc = info?.longDesc
  return (
    <section className="bg-fafafb ptb-70">
      <div className="container">
        <div className="services-details-info custom-h2">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="content">
                <h2>
                  <img src={starIcon} alt="banner" />
                  {` ${title}`}
                </h2>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceInfo
