import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import starIcon from "../../assets/images/star-icon.png"

const ServiceDeliverables = ({ deliverables }) => {
  const title = deliverables?.title ?? "Deliverables"
  const longDesc = deliverables?.longDesc
  const deliverableImage =
    deliverables?.media?.localFile?.childImageSharp?.original?.src
  return (
    <section>
      <div className="container">
        <div className="services-details-info custom-h2">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="content">
                <h2>
                  <img src={starIcon} alt="banner" />
                  {` ${title}`}
                </h2>

                <ReactMarkdown
                  children={longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {deliverables.media ? (
                <div className="image">
                  <img src={deliverableImage} alt="Deliverable" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceDeliverables
