import React from "react"
import starIcon from "../../assets/images/star-icon.png"

const ServiceBenefits = ({ benefits, serviceId }) => {
  const title = benefits?.title ?? "Service Benefits"
  const benefitsList = benefits?.benefitsList
  return (
    <section className="bg-fafafb ptb-70">
      <div className="container">
        <div className="services-details-info custom-h2">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <h2>
                <img src={starIcon} alt="banner" />
                {` ${title}`}
              </h2>
            </div>
            {benefitsList?.map((benefits, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  key={`${serviceId}-benefits-${index}`}
                >
                  <p>{benefits.desc}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceBenefits
