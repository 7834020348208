import React from "react"
import { Link } from "gatsby"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import starIcon from "../../assets/images/star-icon.png"

const ServiceBanner = ({ banner }) => {
  const title = banner?.title || "Service Title"
  const longDesc = banner?.longDesc
  const link = banner?.link
  const linkText = banner?.linkText
  const bannerImage = banner?.media?.localFile?.childImageSharp?.original?.src
  return (
    <section className="pb-70">
      <div className="container">
        <div className="services-details-info custom-h2">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="content">
                <h1>
                  <img src={starIcon} alt="banner" />
                  {` ${title}`}
                </h1>
                <ReactMarkdown
                  children={longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
                {link ? (
                  <Link to={link} className="default-btn">
                    <i className="flaticon-web"></i>
                    {linkText}
                    <span></span>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="image">
                {banner.media ? <img src={bannerImage} alt="about" /> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceBanner
