import React from "react"
import { Link } from "gatsby"
import shape9 from "../../assets/images/shape/vector-shape9.png"
import shape10 from "../../assets/images/shape/vector-shape10.png"

const ServiceStartProject = (props) => {
  const { title, shortDesc, linkText, link } = props?.CTA

  return (
    <div className="project-start-area bg-color ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-12">
            <div className="project-start-content">
              <h2>{title}</h2>
              <p>{shortDesc}</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <div className="project-start-content">
              <Link to={link} className="default-btn">
                <i className="flaticon-web"></i>
                {linkText}
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape9">
        {/* FIXME: Background Side Image - Make it dynamic .i.e it should come from strapi */}
        <img src={shape9} alt="about" />
      </div>
      <div className="vector-shape10">
        {/* FIXME: Background Side Image - Make it dynamic .i.e it should come from strapi */}
        <img src={shape10} alt="about" />
      </div>
    </div>
  )
}

ServiceStartProject.defaultProps = {
  CTA: {
    title: "We Like to Start Your Project With Us",
    shortText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    link: "/contact",
    linkText: "Consult Us Today",
  }
}

export default ServiceStartProject
