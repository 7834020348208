import React from "react"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import starIcon from "../../assets/images/star-icon.png"

const ServiceWhatDoWeDo = ({ whatDoWeDo }) => {
  const title = whatDoWeDo?.title ?? "What Do We Do"
  const longDesc = whatDoWeDo?.longDesc
  const bannerImage =
    whatDoWeDo?.media?.localFile?.childImageSharp?.original?.src
  return (
    <section className="pt-70">
      <div className="container">
        <div className="services-details-info custom-h2">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <h2>
                <img src={starIcon} alt="banner" />
                {` ${title}`}
              </h2>
              <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="image">
                {whatDoWeDo?.media ? <img src={bannerImage} alt="about" /> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceWhatDoWeDo
