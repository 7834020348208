import React from "react"
import ServiceBanner from "./ServiceBanner"
import ServiceBenefits from "./ServiceBenefits"
import ServiceDeliverables from "./ServiceDeliverables"
import ServiceInfo from "./ServiceInfo"
import ServicesWeOffer from "./ServicesWeOffer"
import ServiceWhatDoWeDo from "./ServiceWhatDoWeDo"

const ServiceDetailContent = ({
  serviceId,
  banner,
  info,
  whatDoWeDo,
  benefits,
  ourServices,
  deliverables,
}) => {
  return (
    <section className="services-details-area ptb-70">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="services-details-desc">
            {banner ? <ServiceBanner banner={banner} /> : ""}

            {info ? <ServiceInfo info={info} /> : ""}

            {whatDoWeDo ? <ServiceWhatDoWeDo whatDoWeDo={whatDoWeDo} /> : ""}

            {benefits ? <ServiceBenefits benefits={benefits} serviceId={serviceId} /> : ""}

            {ourServices ? <ServicesWeOffer serviceId={serviceId} ourServices={ourServices} /> : ""}

            {deliverables ? <ServiceDeliverables deliverables={deliverables} /> : ""}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceDetailContent
