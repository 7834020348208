import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import starIcon from "../../assets/images/star-icon.png"

const ServicesWeOffer = ({ ourServices, serviceId }) => {
  const title = ourServices?.title ?? "Services We Offer"
  const shortDesc = ourServices?.shortDesc
  const services = ourServices?.services ?? null
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>
            <img src={starIcon} alt="banner" />
            {` ${title}`}
          </h2>
          {shortDesc ? (
            <ReactMarkdown children={shortDesc} rehypePlugins={[rehypeRaw]} />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          {services?.map((service, idx) => {
            const { title, longDesc, media } = service
            const iconImageSrc =
              media?.localFile?.childImageSharp?.original?.src
            return (
              <div
                className="col-lg-4 col-sm-6 mb-4"
                key={`${serviceId}-services-${idx}`}
              >
                <div className="single-solutions-box">
                  <div className="icon service-box-icon">
                    <img src={iconImageSrc} alt="Service Icon" />
                  </div>
                  <h3>{title}</h3>
                  {longDesc ? (
                    <ReactMarkdown
                      children={longDesc}
                      rehypePlugins={[rehypeRaw]}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ServicesWeOffer
