import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetailContent from "../components/ServiceDetails/ServiceDetailContent"
import ServiceStartProject from "../components/ServiceDetails/ServiceStartProject"
import Seo from "../components/App/Seo"

const Details = ({ data }) => {
  const {
    id,
    slug,
    title,
    banner,
    info,
    whatDoWeDo,
    benefits,
    ourServices,
    deliverables,
    CTA,
  } = data.serviceDetails

  const { metaTags } = data
  const { metaImage } = metaTags
  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === slug
  })[0]
  return (
    <Layout>
      <Seo
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
        url={`services/${slug}`}
      />
      <Navbar />
      <PageBanner
        pageTitle={title}
        homePageText="Home"
        homePageUrl="/"
        activePageText={title}
      />

      <section className="services-details-area ">
        <div className="row">
          <ServiceDetailContent
            serviceId={id}
            banner={banner}
            info={info}
            whatDoWeDo={whatDoWeDo}
            benefits={benefits}
            ourServices={ourServices}
            deliverables={deliverables}
          />
          <ServiceStartProject CTA={CTA} />
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleServiceSolution($slug: String) {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
    serviceDetails: strapiServiceSolution(slug: { eq: $slug }) {
      id
      title
      slug
      banner {
        title
        longDesc
        linkText
        link
        media {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      info {
        title
        longDesc
      }
      whatDoWeDo {
        title
        longDesc
        media {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      benefits {
        id
        title
        benefitsList {
          id
          desc
        }
      }
      ourServices {
        title
        shortDesc
        services {
          title
          longDesc
          media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
      deliverables {
        title
        longDesc
        media {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      CTA {
        title
        shortDesc
        linkText
        link
      }
    }
  }
`

export default Details
